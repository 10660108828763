import './App.css';

import React from 'react';

const Tokenomics = () => {
  return (
    <div id = "tokenomics" className="tokenomics-section">
      <h2>$JECAK TOKENOMICS</h2>
      <p>
        The JECAK initiative has swiftly galvanized support, achieving a pivotal fundraising milestone by securing over 10 ETH in a remarkably short span on party.app. This quick accumulation of resources reflects the community's belief in the potential and vision behind JACEK.
      </p>
      <ul className='tokenomics-list'>
        <li>Total Supply: 1,000,000,000 $JECAK</li>
        <li>Listed on <a href = "https://dexscreener.com/base/0x288Bd91AaF2aBb51A67Ec4b53b4BCd140CCFc1eE" target="_blank">DexScreener</a>  </li>
        <li>Listed on <a href = "https://dexscreener.com/base/0x288Bd91AaF2aBb51A67Ec4b53b4BCd140CCFc1eE" target="_blank">Geckoterminal</a></li>
        </ul>
      <p>
        The vigor and pace of JECAK's advent are nothing short of extraordinary. Within a day of inception, the launch was propelled into action, signaling a new chapter in the digital narrative inspired by Jacek's innovative legacy. With a valuation that rapidly ascended to match industry benchmarks, JACEK represents not just financial acumen but also the community's collective trust and aspiration.
      </p>
      <p>
        Our digital presence on Farcaster and Twitter keeps us at the heart of conversations, and as the official website, we serve as the cornerstone for all information and updates. JACEK is more than a financial instrument; it’s a celebration of community spirit and a venture into new digital horizons.
      </p>
    </div>
  );
};

export default Tokenomics;
