import React from 'react';
import AboutJacek from './aboutjacek';
import Tokenomics from './tokenomics';
import Footer from './footer';
import './App.css';
import LogoChanger from './logochanger';
import WelcomeMessage from './welcome';
import TagManagerComponent from "./tagmanager"



const NavBar = () => {
  return (
    

    <nav>
      <ul>
        <li><a href="#about">ABOUT $JECAK</a></li>
        <li><a href="#tokenomics">TOKENOMICS</a></li>
        <li><a href="https://app.uniswap.org/swap?outputCurrency=0x288bd91aaf2abb51a67ec4b53b4bcd140ccfc1ee" target="_blank" rel="noopener noreferrer">Buy JACEK on Uniswap</a></li>
      </ul>
    </nav>
  );
};

const About = () => {
  return (
    <section id="about" className='about'>
      <h2>About $JECAK</h2>
      <p>Description of the JECAK token...</p>
    </section>
  );
};

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <NavBar /> 
        </header>
        {/* <div className='App-text'>
        <h1 >Welcome to JACEK's Home</h1></div> */}
      <div className = "App-body">
        <LogoChanger/>
        <div className='welcome-message'>
          <WelcomeMessage/>
        </div>
        
        </div> 
      <div>
      <AboutJacek/>
        </div>  
      <div >
        <Tokenomics/>
        </div>  
      <div>
        
      </div>
      <footer>
      <Footer/>
      </footer>
      <TagManagerComponent/>
      
      
      {/* Render other sections/components here if needed */}
    </div>
    
  );
}

export default App;
