import './App.css';

import React from 'react';


const WelcomeMessage = () => {
  return (
    <div className="Welcome-message">
      <h1>Welcome to Jecak's Home!</h1>
      <p>
        Dive into the world of Jecak, where innovation meets community. Jecak's home is a digital canvas painted with the colors of creativity, camaraderie, and exploration. As the visionary behind the DEGEN token and a symbol of digital advancement, Jecak invites you to explore a realm where possibilities are boundless. Join us in celebrating the spirit of discovery and the joy of creating a future together.
      </p>
    </div>
  );
};

export default WelcomeMessage;