import './App.css';
import React, { useState } from 'react';
import logo1 from "./logo1.png"
import logo2 from "./logo2.png"
import logo3 from "./logo3.png"
import logo4 from "./logo4.png"

const LogoChanger = () => {
  const logos = [logo1, logo2, logo3, logo4];
  const [currentLogo, setCurrentLogo] = useState(2);

  const handleLogoClick = () => {
    console.log('Logo clicked, current index before update:', currentLogo);
    setCurrentLogo((prevLogo) => {
      console.log('Updating logo index to:', (prevLogo + 1) % logos.length);
      return (prevLogo + 1) % logos.length;
    });
  };///
  

  return (
    <img
      src={logos[currentLogo]}
      alt="logo"
      onClick={() => handleLogoClick()}
      className="App-logo"
      style={{cursor: 'pointer', width: '400px', height: '400px' }} // Add size styles if needed
    />
  );
};

export default LogoChanger;
