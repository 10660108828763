import React from 'react';
import './App.css';

// Assuming you have these images in your src/assets folder or adjust the path as needed
import Image1 from "./footer1.png";
import Image2 from "./footer2.png"
import Image3 from './footer3.png';


const Footer = () => {
  return (
    <footer className={"footer"}>
        <a href="https://warpcast.com/~/channel/jacek" target="_blank" rel="noopener noreferrer">
<img src={Image1} alt="Description 1" className="footerImage" /></a>
<a href="https://app.uniswap.org/swap?outputCurrency=0x288bd91aaf2abb51a67ec4b53b4bcd140ccfc1ee" target="_blank" rel="noopener noreferrer">
<img src={Image2} alt="Description 2" className="footerImage" /></a>
<a href="https://twitter.com/tutorialsbyai" target="_blank" rel="noopener noreferrer">
<img src={Image3} alt="Description 3" className="footerImage" /></a>
    
    </footer>
  );
};

export default Footer;
