import React, { useEffect } from 'react';
import TagManager from 'react-gtm-module';

const TagManagerComponent = () => {
  useEffect(() => {
    const tagManagerArgs = {
      gtmId: 'G-KY9MK9L272'
    };

    TagManager.initialize(tagManagerArgs);
  }, []);

  return null; // This component doesn't render anything
};

export default TagManagerComponent;
