import './App.css';

import React from 'react';

const AboutJacek = () => {
  return (
    <div id = "about" className="about-section">
      <h2>THE LEGEND OF $JECAK</h2>
      <p>"Discover Jecak, the innovative hedgehog behind the DEGEN token. Originating from Farcaster's mysterious digital landscape, Jecak embodies the era's creativity and freedom. The DEGEN token celebrates Jecak's foresight, quickly achieving notable value. JECAK's launch, inspired by the enthusiastic crypto community on party.app, successfully secured over 10 ETH, propelled by widespread support. Today, JECAK thrives online, championed by a dedicated community on platforms like Twitter and Farcaster. More than a token, JECAK honors Jecak's trailblazing journey, uniting innovation with community spirit. Celebrate with us the legacy of Jacek, a symbol of boldness and endless curiosity in the digital age."
</p>
    </div>
  );
};

export default AboutJacek;
